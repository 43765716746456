import React from 'react';

import {useAuth0} from '@auth0/auth0-react';
import {AppBar, Button, Toolbar, Typography} from '@mui/material';

const Header = () => {
    const { logout } = useAuth0();

    const handleLogout = () => {
        logout({logoutParams: {returnTo: window.location.origin}});
    };
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    Eyeball
                </Typography>

                {
                    <Button color="inherit" onClick={handleLogout}>Log Out</Button>
                }
            </Toolbar>
        </AppBar>
    );
};

export default Header;